import React from 'react'
import Navbar from './Navbar'
import Silder from './Silder'

const Home = () => {
  return (
    <>
      <Navbar />

      <section>
        <div className="container-fluid p-0 " >
          <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" >
            <div className="carousel-inner FontFamilyMontSerret">
              <div className="carousel-item active  SILDERHEIGHT" style={{ backgroundImage: `url(${require("../img/PS1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ',  }}>
              </div>

              <div className="carousel-item  position-relative SILDERHEIGHT" style={{ backgroundImage: `url(${require("../img/ps2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', }}>
              </div>

              <div className="carousel-item  position-relative SILDERHEIGHT" style={{ backgroundImage: `url(${require("../img/ps3.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ',  }}>
              </div>

              <div className="carousel-item  position-relative SILDERHEIGHT" style={{ backgroundImage: `url(${require("../img/ps4.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ',  }}>
              </div>

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span className="carousel-control" style={{ borderRadius: "50%", padding: "13px", width: "45px", height: "50px", backgroundColor: "rgba(0,0,0,0.5)" }} aria-hidden="true">
                {/* <span className="visually-hidden">Previous</span> */}
                <i class="fa-solid fa-angle-left" style={{ fontSize: "18px", fontWeight: "900", color: "#fff" }} ></i>
              </span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span className="carousel-control " style={{ borderRadius: "50%", padding: "13px", width: "45px", height: "50px", backgroundColor: "rgba(0,0,0,0.5)" }} aria-hidden="true">
                {/* <span className="visually-hidden">Next</span> */}
                <i class="fa-solid fa-angle-right" style={{ fontSize: "18px", fontWeight: "900", color: "#fff" }} ></i>
              </span>
            </button>
          </div>
        </div>
      </section>

      <section>
        <div className="container-lg p-lg-2 mb-5">
          <div className='text-center'>
            <div className='OurHostingServices'>
              <h2>
                <strong>
                  Welcome to Pashumitra - Your Trusted Partner in Veterinary Care
                </strong>
              </h2>
              <p>
                At Pashumitra, we are dedicated to the health and wellbeing
                of animals. Our mission is to provide top-notch veterinary
                services and resources for pet owners and livestock farmers
                alike. With our team of experienced veterinarians and support staff,
                we ensure your animals receive the best care possible.
              </p>
            </div>
          </div>
          <div className="row g-4 p-lg-2 laila-semibold">
            <div className="col-lg-4 col-md-6">
              <div className=''>
                <img className='CommonIMG' src={require("../img/UpdateHome1.png")} alt='' />
              </div>
              <h2 className='CommonContent'> COMPANION ANIMALS </h2>
            </div>

            <div className="col-lg-4 col-md-6">
              <div>
                <img className='CommonIMG' src={require("../img/UpdateHome2.png")} alt='' />
              </div>
              <h2 className='CommonContent'> LIVE STOCK </h2>
            </div>

            <div className="col-lg-4 col-md-6">
              <div>
                <img className='CommonIMG' src={require("../img/UpdateHome3.png")} alt='' />
              </div>
              <h2 className='CommonContent'> POULTRY </h2>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-lg p-lg-2 mt-lg-4 mb-5">
          <div className="row g-4 d-flex justify-content-center align-items-center p-2">
            <div className='mb-2 pb-lg-3'>
              <h4 className='text-center  laila-semibold' style={{ fontWeight: "600", letterSpacing: "2px" }} >  PASHUMITRA At Glance </h4>
              <h2 class="hr-lines">
                <i class="fa-solid fa-star" style={{ color: "#048AB2" }} ></i>
              </h2>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className=' p-3 text-white poppins-All' style={{ backgroundColor: "#1F9738", borderRadius: "10px" }} >
                <div className='p-3'>
                  <div className='mb-3' >
                    <i class="fa-sharp fa-solid fa-earth-americas bg-light " style={{ color: "#1F9738", borderRadius: "50px", padding: "15px", fontSize: "26px" }}></i>
                  </div>
                  <div className='mt-3'>
                    <h3 style={{ fontSize: "18px" }} >
                      Compassion
                    </h3>
                    <p className='laila-semibold' style={{ fontSize: "15.3px", color: "#D1D1D1", fontWeight: "400" }}>
                      We treat every animal as if it were our own, offering empathy and understanding to pets and their owners.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className=' p-3 text-white poppins-All' style={{ backgroundColor: "#1F9738", borderRadius: "10px" }} >
                <div className='p-3 '>
                  <div className='mb-3' >
                    <i class="fa-solid fa-suitcase-medical bg-light" style={{ color: "#1F9738", borderRadius: "50px", padding: "15px", fontSize: "26px" }}></i>
                  </div>
                  <div className='mt-3'>
                    <h3 style={{ fontSize: "18px" }} >
                      Excellence
                    </h3>
                    <p className='laila-semibold' style={{ fontSize: "15px", color: "#D1D1D1", fontWeight: "400" }}>
                      Our team of highly qualified veterinarians employs the latest techniques and technologies to provide exceptional care.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className=' p-3 text-white poppins-All' style={{ backgroundColor: "#1F9738", borderRadius: "10px" }} >
                <div className='p-3 '>
                  <div className='mb-3' >
                    <i class="fa-brands fa-servicestack bg-light " style={{ color: "#1F9738", borderRadius: "50px", padding: "15px", fontSize: "26px" }}></i>
                  </div>
                  <div className='mt-3'>
                    <h3 style={{ fontSize: "18px" }} >
                      Integrity
                    </h3>
                    <p className='laila-semibold' style={{ fontSize: "14.9px", color: "#D1D1D1", fontWeight: "400" }}>
                      We believe in transparent communication with our clients, empowering them to make informed decisions about their animals health.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className=' p-3 text-white poppins-All' style={{ backgroundColor: "#1F9738", borderRadius: "10px" }} >
                <div className='p-3 ' >
                  <div className='mb-3' >
                    <i class="fa-solid fa-award bg-light " style={{ color: "#1F9738", borderRadius: "50px", padding: "15px", fontSize: "26px" }}></i>
                  </div>
                  <div className='mt-3'>
                    <h3 style={{ fontSize: "18px" }} >
                      Education
                    </h3>
                    <p className='laila-semibold' style={{ fontSize: "15.2px", color: "#D1D1D1", fontWeight: "400" }}>
                      We strive to educate pet owners and farmers on best practices in animal care and health management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Silder />

    </>
  )
}

export default Home