import React from 'react'
import { NavLink } from "react-router-dom"

const Navbar = () => {
    return (
        <>

            <section>
                <div className='container-fluid d-none d-sm-block' style={{ padding: "6px", backgroundColor: "rgb(7, 14, 38)" }}>
                    <div className="container-lg laila-semibold">
                        <div className="row d-flex  text-white ">
                            <div className="col-lg-12 col-md-12">
                                <div className='d-none d-sm-block'>
                                    <ul className='d-flex align-items-center mt-1 mb-1 pt-1' style={{ columnGap: "40px" }}>
                                        <NavLink className='text-decoration-none' to='tel:9921792104'>
                                            <li className='laila-semibold text-white' style={{ listStyle: "none", fontSize: "13px" }} > <i class="fa-solid fa-phone"></i> &nbsp; +91 9921792104</li>
                                        </NavLink>
                                        <NavLink className='text-decoration-none' to='mailto:pashumitraa@gmail.com'>
                                            <li className='laila-semibold text-white' style={{ listStyle: "none", fontSize: "13px" }}> <i class="fa-regular fa-envelope "></i>  &nbsp;  pashumitraa@gmail</li>
                                        </NavLink>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-sm-none d-block' style={{ backgroundColor: "rgb(7, 14, 38)" }}>
                    <div className="row  pt-3 pb-3 p-2 d-flex justify-content-around align-items-center text-white laila-semibold">
                        <div className="col-5">
                            <div>
                                <NavLink className='text-decoration-none' to='mailto:pashumitraa@gmail.com'>
                                    <li className='laila-semibold text-white' style={{ listStyle: "none", fontSize: "13px" }} > <i class="fa-solid fa-phone"></i>  +91 9921792104</li>
                                </NavLink>
                            </div>
                        </div>

                        <div className="col-7">
                            <div>
                                <NavLink className='text-decoration-none' to='tel:9921792104'>
                                    <li className='laila-semibold text-white' style={{ listStyle: "none", fontSize: "13.5px" }} > <i class="fa-regular fa-envelope"></i> &nbsp; pashumitraa@gmail</li>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <nav className="navbar navbar-expand-lg header-content p-0 " >
                <div className="container-fluid ">
                    <div className='ImgSize'>
                        <NavLink className="text-decoration-none" to='/'>
                            <img className=' ImgHeight mt-3' src={require("../img/logo1.png")} alt="" />
                        </NavLink>
                    </div>
                    <button className="navbar-toggler" style={{ borderRadius: "0px 0px 0px 0px", border: "0px" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa-solid fa-bars " style={{ color: "#32409A" }}   ></i>
                    </button>
                    <div className="collapse navbar-collapse  me-lg-5 pe-lg-5" id="navbarNavDropdown">
                        <ul className="navbar-nav d-flex justify-content-lg-center align-items-lg-center roboto-regular w-100 me-lg-5"  >
                            <li className='nav-item  Header-Size ' >
                                <NavLink className=" text-decoration-none CommomColorHome " to="/"> HOME </NavLink>
                            </li>
                            <li className='nav-item  Header-Size ' >
                                <NavLink className=" text-decoration-none CommomColor" to="/about">ABOUT US </NavLink>
                            </li>
                            <li className='nav-item  Header-Size ' >
                                <NavLink className=" text-decoration-none CommomColor" to="/product"> PRODUCT </NavLink>
                            </li>
                            <li className='nav-item  Header-Size ' >
                                <NavLink className=" text-decoration-none CommomColor" to="/contact">CONTACT US </NavLink>
                            </li>
                            {/* <li className='nav-item  Header-Size ' >
                                <NavLink className=" text-decoration-none CommomColor" to="/Product">  </NavLink>
                            </li> */}

                        </ul>
                        {/* <div className='mt-lg-4 me-lg-5'  >
                            <div id="google_translate_element"></div>
                        </div> */}

                        <div className='mt-lg-3'  >
                            <div id="google_translate_element"></div>
                        </div>
                    </div>

                </div>
            </nav>

        </>
    )
}

export default Navbar