import React from 'react'
import Navbar from './Navbar'

const ProductSubAshienro = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid">
                    <div className="container mt-4 mb-4 pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductUp1.png")} alt='works-at-iclodsoft' />
                                </div>
                                <div className='ContentBox'>
                                    <p className='pt-3'>
                                        <span className='' style={{ color: "#ff0000" }} >
                                            <strong>
                                                RECOMMENDED USAGE (oral) :
                                            </strong>
                                        </span>
                                    </p>
                                    <p>
                                        Cattle 100 ml daily
                                    </p>
                                    <p>
                                        Calves 20 ml twice daily
                                    </p>
                                    <p>
                                        Sheep, Goat & Pigs 20 ml daily
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "18px", color: "#151515", fontWeight: "600" }} > P CAL FORTE </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    CONTAINS:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Each 100ml contains: </p>
                                        <p> Calcium.......3500Mg</p>
                                        <p> Phosphorus.......1750Mg</p>
                                        <p> Vitamin D3.......35000 IU</p>
                                        <p> Vitamin B12.......200 Mgg</p>
                                        <p> Vitamin C.......100Mg</p>
                                        <p> Vitamin H.......1000Mcg</p>
                                        <p> Energy.......260 KCAL</p>
                                        <p> Colour.......ERYTHROCINE</p>
                                        <p>Zinc Mha.....1500Mg</p>
                                        <p>Copper Mha.....500Mg</p>
                                        <p>More.....</p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    BENEFITS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Improve Milk Production
                                        </p>
                                        <p>
                                            Formation of Strong Bones in Growing Animals
                                        </p>
                                        <p>
                                            Improving Immunity
                                        </p>
                                        <p>
                                            It improves mineral availability
                                        </p>
                                        <p>
                                            It improves general health condition in debilitated animals & improves production period on such animals
                                        </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INFORMATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Net Qty : 20 Ltr </p>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>

                            <div className='pt-lg-4'>
                                <div className='d-flex justify-content-lg-center CommonBack'>
                                    <a className='text-decoration-none' href='/product'>
                                        <button className='text-center'>
                                            Product
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductSubAshienro