import React from 'react'
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'
import "./Comman.css"

const About = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#7DCBEC" }} >
                    <div className="row  text-white d-flex justify-content-between align-items-center laila-semibold" style={{ padding: "15px" }} >
                        <div className='col-lg'>
                            <h5 className='mt-2' style={{ fontSize: "20px", fontWeight: "600", letterSpacing: "2px" }} >About Us</h5>
                        </div>
                        <div className="col-lg">
                            <div className=' text-lg-end'>
                                <h5 className='mt-lg-2' style={{ fontSize: "16.5px", fontWeight: "500" }} >
                                    <NavLink className='text-decoration-none text-white' to='/'>
                                        Home
                                    </NavLink>
                                    <i class="fa-solid fa-angle-right ms-2 me-2" style={{ fontSize: "14px", color: "#fff" }} ></i>
                                    <NavLink className='text-decoration-none text-white' to='/about'>
                                        About Us
                                    </NavLink>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-lg p-4 '>
                    <div className='row d-flex justify-content-center align-items-center laila-semibold'>

                        <div className="col-lg-6">
                            <h4 className='mb-lg-4 pb-lg-2 WELCOME-TO-PASHUMITRA'> <strong> WELCOME TO PASHUMITRA </strong> </h4>
                            <div className='mt-3 me-lg-5' style={{ fontSize: "13px", fontWeight: "500", lineHeight: "27px" }} >
                                <p>Pashumitra is one of the largest providers of cattle feed and agricultural products in Maharashtra. We have been helping farmers with their cattle farming, poultry farming, and small scale agriculture needs for over 10 years. Our products are of the highest quality and are backed by our team of experts who are always available to help you get the most out of our products.</p>
                                <p> Pashumitra was established in 2010 with the goal of providing a wide range of cattle feed and agricultural products to farmers in Maharashtra. We provide products that are essential for cattle farming, poultry farming, and small-scale agriculture. Our products are of the highest quality and are designed to help our customers be successful in their farming endeavors.</p>
                                <p> We are proud to be one of the leading providers of cattle feed and agricultural products in Maharashtra. We are committed to helping our customers achieve their goals and improve their operations. We offer a wide range of products that are essential for successful farming. Contact us today to learn more about our products and how we can help you!</p>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className='' >
                                <img className='w-100 ' src={require("../img/vgv.jpg")} alt='works-at-iclodsoft' />
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section>
                <div className="container-lg mb-4 p-lg-4">
                    <div className="row g-4 p-1">
                        <div className="col-lg-6">
                            <div className='laila-semibold h-100' style={{ padding: "35px", borderRadius: "10px", backgroundColor: "rgba(0, 87, 244, 0.666)" }} >
                                <div className='p-lg-3'>
                                    <div className='mb-4'>
                                        <h2 className='mb-2' style={{ fontSize: "2rem", color: "#fff", fontWeight: "600", letterSpacing: "1px" }} > <strong> Vision </strong> </h2>
                                        <div className='' >
                                            <div className='BorderMoreArticles4' >
                                            </div>
                                        </div>

                                    </div>
                                    <p className='' style={{ fontSize: "19px", color: "#fff", fontWeight: "400" }} >
                                        To be the leading veterinary service provider in our community, known for our commitment to animal welfare and customer education.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className='laila-semibold h-100' style={{ padding: "35px", borderRadius: "10px", backgroundColor: "#32409A" }} >
                                <div className='p-lg-3'>
                                    <div className='mb-4'>
                                        <h2 className='mb-2' style={{ fontSize: "2rem", color: "#fff", fontWeight: "600", letterSpacing: "1px" }} > <strong> Mission </strong> </h2>
                                        <div className='' >
                                            <div className='BorderMoreArticles4' >
                                            </div>
                                        </div>

                                    </div>
                                    <p className='' style={{ fontSize: "17.8px", color: "#fff", fontWeight: "400" }} >
                                        To enhance the quality of animal life through preventive care, education, and compassionate treatment, ensuring that every animal receives the attention and respect it deserves.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-lg mb-lg-2 PRODUCT-QUALITY'  >
                    <div className="row" style={{ borderRadius: "10px" }}>
                        <div className='laila-semibold Coloorr' style={{ padding: "30px", borderRadius: "10px" }} >
                            <div className='p-lg-4'>
                                <div className='mb-3'>
                                    <h2 className='mb-2' style={{ fontSize: "1.7rem", color: "#111", fontWeight: "600", letterSpacing: "1px" }} > <strong> PRODUCT QUALITY </strong> </h2>
                                    <h6 className='mb-3' style={{ fontSize: "1rem", color: "#111", fontWeight: "500", letterSpacing: "1px" }} > Committed to Quality Care  </h6>
                                    <div className='' >
                                        <div className='BorderMoreArticles2' >
                                        </div>
                                    </div>
                                </div>
                                <p className='me-lg-5' style={{ fontSize: "18px", color: "#111", fontWeight: "400" }} >
                                    At Pashumitra, we take pride in providing the highest quality veterinary services to your beloved pets. Our unwavering commitment to excellence is evident in every aspect of our practice. From our state-of-the-art facilities to our compassionate and skilled staff, we strive to deliver top-notch care that meets the unique needs of each animal.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default About