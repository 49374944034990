import React from 'react'
import Navbar from './Navbar'

const ProductSubAshiverPlus = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid">
                    <div className="container mt-4 mb-4 pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductUp3.png")} alt='works-at-iclodsoft' />
                                </div>

                                <div className='ContentBox'>
                                    <p className='pt-3'>
                                        <span className='' style={{ color: "#ff0000" }} >
                                            <strong>
                                                RECOMMENDED USAGE (oral) :
                                            </strong>
                                        </span>
                                    </p>
                                    <p>
                                        Large animal : 50 to 100 ml daily
                                    </p>
                                    <p>
                                        Small animal : 20 to 25 ml per day
                                    </p>
                                    <p>
                                        Sheep, Goat & Pig : 10 to 15 ml per day
                                    </p>
                                    <p>
                                        Pups & Cats : 5 ml twice a day
                                    </p>
                                    <p>
                                        Grover : 20 ml daily
                                    </p>
                                    <p>
                                        Layer : 50 ml daily
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "18px", color: "#151515", fontWeight: "600" }} > P LIV (Liver Tonic) </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    CONTAINS:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Each 100 ml contains </p>
                                        <p> Liver Extract...... 800 mg </p>
                                        <p> Yeast Extract....400 mg </p>
                                        <p> Choline Chloride.....2000 mg </p>
                                        <p> Vitamin B12.....100 mcg</p>
                                        <p> Ascorbic Acid.....100 mcg </p>
                                        <p> Protien Hydrolysate......1000 mg</p>
                                        <p> Biotin..........100 mcg </p>
                                        <p> D-Penthanol...50 mg</p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    BENEFITS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            The P Liv stimulates liver & improves its functioning.
                                        </p>
                                        <p>
                                            The P Liv improves blood production.
                                        </p>
                                        <p>
                                            P Liv reduces morbidity.
                                        </p>
                                        <p>
                                            It improves milk quality especially milk fat & protein content.
                                        </p>
                                        <p>
                                            P Liv improves immunity.
                                        </p>


                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INFORMATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>Net Quantity : 1ltr </p>
                                        <p>Net Quantity : 500ml </p>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>

                            <div className='pt-lg-4'>
                                <div className='d-flex justify-content-lg-center CommonBack'>
                                    <a className='text-decoration-none' href='/product'>
                                        <button className='text-center'>
                                            Product
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductSubAshiverPlus