import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Comman.css"

const Footer = () => {
  return (
    <>

      <section>
        <div className="container-fluid pt-lg-3 pb-3 p-0 d-none d-sm-block" style={{ backgroundColor: "rgb(7, 14, 38)" }}>
          <div className="container laila-semibold pt-lg-4">
            <div className="row p-lg-3 g-4 d-flex justify-content-lg-center">
              <div className="col-lg-3 col-md-6  text-white ">
                <div className='mb-4' >
                  <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-2' style={{ fontSize: "21px", fontWeight: "800" }} > About Us </h6>
                </div>
                <div className=''>
                  <p className='' style={{ fontSize: "13px",fontWeight:"500",lineHeight:"22px" }} >
                    Welcome to Pashumitra, where compassion meets excellence in veterinary medicine. Founded with the commitment to the health and well-being of all animals, we pride ourselves on being a trusted partner for pet owners and livestock farmers alike.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6   ">
                <div className='mb-4 ms-lg-4 ps-lg-3' >
                  <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-2' style={{ fontSize: "21px", fontWeight: "800" }} >Quick links</h6>
                </div>
                <div className='ms-lg-5'>
                  <nav className='h1FontFamily1 ' style={{ fontSize: "17px", }}>
                    <div className='mt-3 fontFamilyHeader1 ' style={{ fontSize: "17px", lineHeight: "17px" }}>
                      <NavLink to='/' className='text-decoration-none '> <p className='FOOTERLINK'>HOME</p></NavLink>
                      <NavLink to='/about' className='text-decoration-none '> <p className='FOOTERLINK'>ABOUT US</p></NavLink>
                      <NavLink to='/Product' className='text-decoration-none '> <p className='FOOTERLINK'>PRODUCTS</p></NavLink>
                      <NavLink to='/contactus' className='text-decoration-none '> <p className='FOOTERLINK'>CONTACT US</p></NavLink>
                    </div>
                  </nav>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 text-white ">
                <div className='' >
                  <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-2 mb-4' style={{ fontSize: "21px", fontWeight: "800" }} >Quick connect</h6>
                </div>
                <div>
                  <nav className='h1FontFamily1 ' style={{ fontSize: "17px", }}>
                    <div className='mt-3 fontFamilyHeader1 ' style={{ fontSize: "17px", lineHeight: "17px" }}>
                      {/* <NavLink to='tel:9921792104' className='text-decoration-none '> <p className='FOOTERLINK'><i class="fa-solid fa-phone me-3"></i>+91 9921792104</p></NavLink> */}
                      <NavLink to='tel:9921792104' className='text-decoration-none '> <p className='FOOTERLINK'><i class="fa-solid fa-phone me-3"></i>+91 9921792104</p></NavLink>
                      <NavLink to='mailto:pashumitraa@gmail.com' className='text-decoration-none '> <p className='FOOTERLINK'><i class="fa-solid fa-envelope me-3"></i>pashumitraa@gmail.com</p></NavLink>
                    </div>
                  </nav>
                </div>
              </div>

              <div className='col-lg-3 col-md-6 '>
                <div className=''>
                  <NavLink to='/'>
                    <img className=' ImgHeightFooter' src={require("../img/logo1.png")} alt="" />
                  </NavLink>
                </div>

                <div className='row d-flex justify-content-center mt-3'>
                  <div className='col-lg-2 col-3 text-center '>
                    <NavLink to="https://www.pashumitra.in/ " target="_blank"><i className="fa-brands fa-facebook fs-2 text-white"></i></NavLink>
                  </div>

                  <div className='col-lg-2 col-3 text-center'>
                    <NavLink to='https://www.pashumitra.in/' target="_blank"><i className="fa-brands fa-linkedin fs-2 text-white"></i> </NavLink>
                  </div>

                  <div className='col-lg-2 col-3 text-center '>
                    <NavLink to='https://wa.me/9921792104' target="_blank"><i class="fa-brands fa-whatsapp fs-2 text-white"></i> </NavLink>
                  </div>

                  <div className='col-lg-2 col-3 text-center '>
                    <NavLink to='https://www.pashumitra.in/' target="_blank"><i className="fa-brands fa-instagram fs-2 text-white"></i> </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-center mt-5 text-white  h1FontFamilyFooter ' >
              © 2023 Copyright:  Pashumitra Animal Health Care   All Rights Reserved.
            </div>
          </div>
        </div>

        <div className="container-fluid pt-lg-3 pb-3 p-0 d-sm-none d-block " style={{ backgroundColor: "rgb(7, 14, 38)" }}>
          <div className="container p-4 pt-lg-4">
            <div className="row p-lg-3 g-4 d-flex justify-content-around">

              <div className="col-lg-3 text-white ">
                <div className='mb-3' >
                  <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-2' style={{ fontSize: "21px", fontWeight: "800" }} > About Us </h6>
                </div>
                <div className=''>
                  <p className='' style={{ fontSize: "16px" }} >
                    Welcome to Pashumitra, where compassion meets excellence in veterinary medicine. Founded with the commitment to the health and well-being of all animals, we pride ourselves on being a trusted partner for pet owners and livestock farmers alike.
                  </p>
                </div>
              </div>

              <div className="col-lg-3  text-white ">
                <div className='mb-4' >
                  <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-2' style={{ fontSize: "21px", fontWeight: "800" }} >Quick links</h6>
                </div>
                <div className=''>
                  <nav className='h1FontFamily1 ' style={{ fontSize: "17px", }}>
                    <div className='mt-3 fontFamilyHeader1 ' style={{ fontSize: "17px", lineHeight: "17px" }}>
                      <NavLink to='/' className='text-decoration-none '> <p className='FOOTERLINK'>HOME</p></NavLink>
                      <NavLink to='/about' className='text-decoration-none '> <p className='FOOTERLINK'>ABOUT US</p></NavLink>
                      <NavLink to='/Product' className='text-decoration-none '> <p className='FOOTERLINK'>PRODUCTS</p></NavLink>
                      <NavLink to='/contactus' className='text-decoration-none '> <p className='FOOTERLINK'>CONTACT US</p></NavLink>
                    </div>
                  </nav>
                </div>
              </div>

              <div className="col-lg-3  text-white ">
                <div className='' >
                  <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-2 mb-4' style={{ fontSize: "21px", fontWeight: "800" }} >Quick connect</h6>
                </div>
                <div>
                  <nav className='h1FontFamily1 ' style={{ fontSize: "17px", }}>
                    <div className='mt-3 fontFamilyHeader1 ' style={{ fontSize: "17px", lineHeight: "17px" }}>
                      <NavLink to='tel:9921792104' className='text-decoration-none '> <p className='FOOTERLINK'><i class="fa-solid fa-phone me-3"></i>+91 9921792104</p></NavLink>
                      {/* <NavLink to='tel:9921792104' className='text-decoration-none '> <p className='FOOTERLINK'><i class="fa-solid fa-phone me-3"></i>+91 9921792104</p></NavLink> */}
                      <NavLink to='mailto:pashumitraa@gmail.com' className='text-decoration-none '> <p className='FOOTERLINK'><i class="fa-solid fa-envelope me-3"></i>pashumitraa@gmail.com</p></NavLink>
                    </div>
                  </nav>
                </div>
              </div>

              <div className='col-lg-3  '>
                <div className=''>
                  <NavLink to='/'>
                    <img className=' ImgHeightFooter' src={require("../img/logo1.png")} alt="" />
                  </NavLink>
                </div>

                <div className='row d-flex mt-3'>
                  <div className='col-lg-2 col-2 '>
                    <NavLink to="https://www.pashumitra.in/ " target="_blank"><i className="fa-brands fa-facebook fs-2 text-white"></i></NavLink>
                  </div>

                  <div className='col-lg-2 col-2 '>
                    <NavLink to='https://www.pashumitra.in/' target="_blank"><i className="fa-brands fa-linkedin fs-2 text-white"></i> </NavLink>
                  </div>

                  <div className='col-lg-2 col-2 '>
                    <NavLink to='https://wa.me/9921792104' target="_blank"><i class="fa-brands fa-whatsapp fs-2 text-white"></i> </NavLink>
                  </div>

                  <div className='col-lg-2 col-2'>
                    <NavLink to='https://www.pashumitra.in/' target="_blank"><i className="fa-brands fa-instagram fs-2 text-white"></i> </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-center mt-5 text-white  h1FontFamilyFooter ' >
              © 2023 Copyright:  Pashumitra Animal Health Care   All Rights Reserved.
            </div>
          </div>
        </div>
      </section>

    </>


  )
}

export default Footer