import React from 'react'
import "./Silder.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Silder = () => {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1428 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        desktop: {
            breakpoint: { max: 1428, min: 997 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 997, min: 767 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },

    };

    return (
        <>


            <div className="container-lg mt-4 mb-5 pb-4"  >
                <div className="row d-flex justify-content-center align-items-center text-center laila-semibold  mt-5">
                    <div className='mb-4 pb-lg-4'>
                        <h4 className='text-center  laila-semibold ' style={{ fontWeight: "600", letterSpacing: "2px" }} > Explore Our Services </h4>
                        <h2 class="hr-lines">
                            <i class="fa-solid fa-star" style={{ color: "#048AB2" }} ></i>
                        </h2>
                    </div>
                    <Carousel responsive={responsive}
                        autoPlay={true}
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        infinite={true}
                        partialVisible={false}
                        >

                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSizeSilder img-fluid mx-auto d-block"
                                    src={require("../img//SilderUp1.jpg")}
                                    alt=""
                                />
                                <h3> Routine Check-ups </h3>
                            </div>
                        </div>

                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSizeSilder img-fluid  text-center"
                                    src={require("../img//SilderUp2.jpg")}
                                    alt=""
                                />
                                <h3> Vaccinations </h3>
                            </div>
                        </div>

                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSizeSilder img-fluid  text-center"
                                    src={require("../img//SilderUp3.jpg")}
                                    alt=""
                                />
                                <h3> Emergency Services </h3>
                            </div>
                        </div>



                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSizeSilder img-fluid  text-center"
                                    src={require("../img//SilderUp4.jpg")}
                                    alt=""
                                />
                                <h3> Nutritional Counseling </h3>
                            </div>
                        </div>

                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSizeSilder img-fluid mx-auto d-block"
                                    src={require("../img//SilderUp5.jpg")}
                                    alt=""
                                />
                                <h3> Surgical Procedures </h3>
                            </div>
                        </div>

                        <div>
                            <div className='main_size'>
                                <img
                                    className="ImgSizeSilder img-fluid  text-center"
                                    src={require("../img//SilderUp6.jpg")}
                                    alt=""
                                />
                                <h3> Preventative Care </h3>
                            </div>
                        </div>

                    </Carousel>
                </div>
            </div>


        </>
    )
}

export default Silder