import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Navbar from './Navbar'

const Contact = () => {

    const [Fname, setFname] = useState();
    const [Email, setEmail] = useState();
    const [Number, setNumber] = useState();
    const [Message, setMessage] = useState();
    const [errors, setErrors] = useState({});

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            errors.Fname = ""; errors.Number = ""; errors.Email = ""; errors.Message = "";

            var url = "https://wa.me/9921792104?text="
            + " Name : " + Fname + " Contact Number : " + Number + " Email : " + Email + " Message : " + Message;

        window.open(url, '_blank').focus();

        }
        else {
            setErrors(errors);
        }

        setEmail("");
        setFname("");
        setNumber("");
        setMessage("");
        
    };

    const validateForm = () => {
        const errors = {};
        if (!Fname) {
            errors.Fname = 'Name is required';
        }
        else if (Fname.length < 4) {
            errors.Fname = "Name Must have 4 Char";
        }
        if (!Number) {
            errors.Number = 'Number is required';
        }
        else if (Number.length !== 10) {
            errors.Number = 'Number must be 10 digit';
        }
        if (!Email) {
            errors.Email = "Email is required"
        }
        if (!Message) {
            errors.Message = 'Message is required';
        }

        return errors;
    };

    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#7DCBEC" }} >
                    <div className="row  text-white d-flex justify-content-between align-items-center laila-semibold" style={{ padding: "15px" }} >
                        <div className='col-lg'>
                            <h5 className='mt-2' style={{ fontSize: "20px", fontWeight: "600", letterSpacing: "2px" }} >Contact</h5>
                        </div>
                        <div className="col-lg">
                            <div className=' text-lg-end'>
                                <h5 className='mt-lg-2' style={{ fontSize: "16.5px", fontWeight: "500" }} >
                                    <NavLink className='text-decoration-none text-white' to='/'>
                                        Home
                                    </NavLink>
                                    <i class="fa-solid fa-angle-right ms-2 me-2" style={{ fontSize: "14px", color: "#fff" }} ></i>
                                    <NavLink className='text-decoration-none text-white' to='/contact'>
                                        Contact
                                    </NavLink>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg ProductPadding" >
                    <div className="row g-4 d-flex justify-content-center align-items-center  laila-semibold">
                        <div className='col-md-6'>
                            <form class="row g-4" ref={form} onSubmit={sendEmail}>
                                <div class="col-md-12 ">
                                    <input type="text" className="form-control" style={{ height: "45px", border: "1px solid red",fontSize:"13px" }} id="inputText1" placeholder='Fullname' name='Fname' onChange={e => setFname(e.target.value)} value={Fname} />
                                    {errors.Fname && <div className='text-danger' style={{fontWeight:"500",fontSize:"14px",marginTop:"10px",marginLeft:"5px"}}>{errors.Fname}</div>}
                                </div>

                                <div className="col-12">
                                    <input type="number" class="form-control " style={{ height: "45px", border: "1px solid red",fontSize:"13px" }} id="inputNumber" placeholder="Number" name='number' onChange={e => setNumber(e.target.value)} value={Number} />
                                    {errors.Number && <div className='text-danger' style={{fontWeight:"500",fontSize:"14px",marginTop:"10px",marginLeft:"5px"}}>{errors.Number}</div>}
                                </div>

                                <div className="col-lg-12">
                                    <input type="email" class="form-control " style={{ height: "45px", border: "1px solid red",fontSize:"13px" }} id="inputEmail4" placeholder='Email address' name='email' onChange={e => setEmail(e.target.value)} value={Email} />
                                    {errors.Email && <div className='text-danger' style={{fontWeight:"500",fontSize:"14px",marginTop:"10px",marginLeft:"5px"}}>{errors.Email}</div>}
                                </div>


                                <div className='col-12'>
                                    <textarea type="text" class="form-control " style={{ height: "100px", border: "1px solid red",fontSize:"13px" }} id="inputText3" placeholder='Message' name='message' onChange={e => setMessage(e.target.value)} value={Message} />
                                    {errors.Message && <div className='text-danger' style={{fontWeight:"500",fontSize:"14px",marginTop:"10px",marginLeft:"5px"}}>{errors.Message}</div>}
                                </div>

                                <div class="col-12">
                                    <button type="submit" class="btn btn SubmitButton" value="Send">SEND</button>
                                </div>
                            </form>
                        </div>

                        <div className='col-md-6 '>
                            <div className=''  >
                                <img className='w-100 ' src={require("../img/UpdateContact.png")} alt='works-at-iclodsoft' />
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </>
    )
}

export default Contact