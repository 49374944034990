import React from 'react'
import Navbar from './Navbar'

const ProductSubExfreeSpot = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid">
                    <div className="container mt-4 mb-4 pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductUp6.png")} alt='works-at-iclodsoft' />
                                </div>
                                <div className='ContentBox'>
                                    <p className='pt-3'>
                                        <span className='' style={{ color: "#ff0000" }} >
                                            <strong>
                                                RECOMMENDED USAGE (oral) :
                                            </strong>
                                        </span>
                                    </p>
                                    <p>
                                        Cattle & Buffalo: 20-50ml daily for 10-15 days
                                    </p>
                                    <p>
                                        Sheep & Goat: 5-10ml daily for 10-15 days
                                    </p>
                                    <p>
                                        Chicks: 5ml/100 birds dally
                                    </p>
                                    <p>
                                        Growers: 7 ml/100 birds daily Layers: 10ml/100 birds daily
                                    </p>

                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "18px", color: "#151515", fontWeight: "600" }} > ANIGAIN </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    CONTAINS:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Vitamin A ......... 450000 IU</p>
                                        <p> Vitamin D3 ......... 250000  IU           </p>
                                        <p> Vitamin E ......... 2000  mg  </p>
                                        <p> Niacin ......... 3000  mg  </p>
                                        <p> Biotin ......... 3000  mcg  </p>
                                        <p> Excipient ......... Q.S </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    BENEFITS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Bone & Skeletal Health
                                        </p>
                                        <p>Reproductive Performance</p>
                                        <p>Maintains high lactation pregnancy</p>
                                        <p>Growth & Development</p>
                                        <p>prevention of Deficiency related Conditions</p>
                                        <p>Maintains Overall Health, helps in a healthy
                                            pregnancy, improves fertility & production of cattle</p>
                                        <p>Should be given for immunity building and fighting for disease in animals.</p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INFORMATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>Net Quantity : 1LTR </p>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>

                            <div className='pt-lg-4'>
                                <div className='d-flex justify-content-lg-center CommonBack'>
                                    <a className='text-decoration-none' href='/product'>
                                        <button className='text-center'>
                                            Product
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductSubExfreeSpot