import React from 'react'
import Navbar from './Navbar'

const Bufftone = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid">
                    <div className="container mt-4 mb-4 pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductUp9.png")} alt='works-at-iclodsoft' />
                                </div>

                                <div className='ContentBox'>
                                    <p className='pt-3'>
                                        <span className='' style={{ color: "#ff0000" }} >
                                            <strong>
                                                RECOMMENDED USAGE (oral) :
                                            </strong>
                                        </span>
                                    </p>
                                    <p>
                                        50-100 gm per animal per day.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "18px", color: "#151515", fontWeight: "600" }} > BUFFTONE </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    CONTAINS:
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Bufftone is a purified bicarbonate of sodium, Hydrated sodium calcium alminu silicate, Oxide of magnesium, Niacinamide, Live Yeast Culture, Jivanti, Shatavar, Leptadenia & Zinc, Copper, Cobalt with appropriate Ratio
                                        </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    BENEFITS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Stabilized rumen pH & bind mycotoxin.
                                        </p>
                                        <p>
                                            Enhances dry matter intake & fiber digestibility.
                                        </p>
                                        <p>
                                            Enhance energy from feeds via microbial digestion.
                                        </p>
                                        <p>
                                            Enhance milk yield & milk fat.
                                        </p>
                                        <p>
                                            Prevents condition like lameness, heat stress, mastitis
                                        </p>
                                        <p>
                                            Diarrhoea, poor BCS due to acidosis
                                        </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INFORMATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>Net Quantity : 300GM </p>
                                        <p> &nbsp; </p>
                                    </div>
                                </div>
                            </div>

                            <div className='pt-lg-4'>
                                <div className='d-flex justify-content-lg-center CommonBack'>
                                    <a className='text-decoration-none' href='/product'>
                                        <button className='text-center'>
                                            Product
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Bufftone